import {
  Typography,
  Button,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSiteDataApi } from "../utilities/SiteDataProvider";

const Accounting = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [accountingData, setAccountingData] = useState({
    outstanding: [],
    matchedTransactions: [],
    unmatchedBills: [],
    unmatchedCredits: [],
  });

  const defaultValues = {
    dateRange: "2024-06", // Default for example
  };

  const { register, handleSubmit } = useForm({ defaultValues });
  const [siteDataContext] = useSiteDataApi();

  const onSubmit = async (data) => {
    setSubmitSpinner(true);

    const { manufacturer } = data;

    const res = await fetch("/api/accounting-data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": siteDataContext.thhcsfrtoken,
      },
      body: JSON.stringify(data),
    });
    const results = await res.json();
    console.log("manufacturer", manufacturer);
    console.log("results", results);
    //filter the bills on the manufacturer which is Bill[x].VendorRef.name
    results.data.transactions.billsInDateRange.QueryResponse.Bill =
      results.data.transactions.billsInDateRange.QueryResponse.Bill.filter(
        (bill) => bill.VendorRef.name === manufacturer
      );
    //do the same for credits
    results.data.transactions.creditsInDateRange.QueryResponse.VendorCredit =
      results.data.transactions.creditsInDateRange.QueryResponse.VendorCredit.filter(
        (credit) => credit.VendorRef.name === manufacturer
      );

    let outstanding = results.data.outstanding || [];
    let bills =
      results.data.transactions?.billsInDateRange?.QueryResponse?.Bill || [];
    let credits =
      results.data.transactions?.creditsInDateRange?.QueryResponse
        ?.VendorCredit || [];

    let matchedTransactions = [];
    let unmatchedBills = [];
    let unmatchedCredits = [];

    // Extract year and month from the dateRange provided in the form
    const [year, month] = data.dateRange.split("-");

    // Filter by transaction date for the provided month
    outstanding = outstanding.filter((transaction) => {
      const transactionMonth = transaction.transaction_date.slice(0, 7);
      return transactionMonth === `${year}-${month}`;
    });

    // Matching Logic: Remove matched transactions from outstanding
    outstanding = outstanding.filter((transaction) => {
      const billMatch = bills.find((bill) =>
        bill.Line.some(
          (line) => line.DocNumber === transaction.transaction_number
        )
      );
      const creditMatch = credits.find(
        (credit) => credit.TxnID === transaction.transaction_number
      );

      if (billMatch || creditMatch) {
        matchedTransactions.push(transaction);
        return false; // Remove from outstanding if matched
      }
      return true;
    });

    // Set Unmatched Bills and Credits
    unmatchedBills = bills.filter(
      (bill) =>
        !outstanding.some((trans) =>
          bill.Line.some((line) => line.DocNumber === trans.transaction_number)
        )
    );
    unmatchedCredits = credits.filter(
      (credit) =>
        !outstanding.some((trans) => credit.TxnID === trans.transaction_number)
    );

    setAccountingData({
      outstanding,
      matchedTransactions,
      unmatchedBills,
      unmatchedCredits,
    });

    setSubmitSpinner(false);
  };

  // Helper function to deduplicate client names
  const deduplicateClientName = (clientName) => {
    if (!clientName) return "N/A";
    const uniqueNames = [...new Set(clientName.split(";"))]; // Split by ';' and deduplicate
    return uniqueNames.join(", ");
  };

  // Helper function to render unmatched bills
  const renderBillRows = (bills) => {
    if (!Array.isArray(bills) || bills.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            No unmatched bills found.
          </TableCell>
        </TableRow>
      );
    }

    return bills.map((bill, index) => {
      const line = bill.Line[0]; // Assuming the first line item
      return (
        <TableRow key={index}>
          <TableCell>{bill.TxnDate || "N/A"}</TableCell>
          <TableCell>{bill.DocNumber || "N/A"}</TableCell> {/* Invoice */}
          <TableCell>{line?.Description || "N/A"}</TableCell>{" "}
          {/* Description */}
          <TableCell>
            {line?.AccountBasedExpenseLineDetail?.CustomerRef?.name || "N/A"}
          </TableCell>{" "}
          {/* Patient */}
          <TableCell>{bill.TotalAmt || "N/A"}</TableCell>
        </TableRow>
      );
    });
  };

  // Helper function to render unmatched credits
  const renderCreditRows = (credits) => {
    if (!Array.isArray(credits) || credits.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            No unmatched credits found.
          </TableCell>
        </TableRow>
      );
    }

    return credits.map((credit, index) => (
      <TableRow key={index}>
        <TableCell>{credit.TxnDate || "N/A"}</TableCell> {/* Date */}
        <TableCell>{credit.DocNumber || "N/A"}</TableCell> {/* Invoice */}
        <TableCell>{credit.PrivateNote || "N/A"}</TableCell> {/* Description */}
        <TableCell>{credit.VendorName || "N/A"}</TableCell>{" "}
        {/* Vendor/Patient */}
        <TableCell>{credit.Balance || "N/A"}</TableCell> {/* Amount */}
      </TableRow>
    ));
  };

  // Helper function to render outstanding transactions
  const renderOutstandingRows = (outstanding) => {
    if (!Array.isArray(outstanding) || outstanding.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            No outstanding transactions found.
          </TableCell>
        </TableRow>
      );
    }

    return outstanding.map((transaction, index) => (
      <TableRow key={index}>
        <TableCell>{transaction.transaction_date || "N/A"}</TableCell>
        <TableCell>{transaction.transaction_number || "N/A"}</TableCell>
        <TableCell>{transaction.description || "N/A"}</TableCell>
        <TableCell>
          {deduplicateClientName(transaction.client_name) || "N/A"}
        </TableCell>
        <TableCell>{transaction.amount || "N/A"}</TableCell>
      </TableRow>
    ));
  };

  // Helper function to render matched transactions
  const renderMatchedTransactionRows = (matchedTransactions) => {
    if (
      !Array.isArray(matchedTransactions) ||
      matchedTransactions.length === 0
    ) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            No matched transactions found.
          </TableCell>
        </TableRow>
      );
    }

    return matchedTransactions.map((transaction, index) => (
      <TableRow key={index}>
        <TableCell>{transaction.transaction_date || "N/A"}</TableCell>
        <TableCell>{transaction.transaction_number || "N/A"}</TableCell>
        <TableCell>{transaction.description || "N/A"}</TableCell>
        <TableCell>
          {deduplicateClientName(transaction.client_name) || "N/A"}
        </TableCell>
        <TableCell>{transaction.amount || "N/A"}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Grid m={3} container>
      {/* Form for Date Range Submission */}
      <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
        <Typography
          textAlign="center"
          variant="h4"
          gutterBottom
          style={{ marginBottom: "30px" }}
        >
          Accounting Reconciliation
        </Typography>

        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={2}></Grid>
          <Grid item container justifyContent="space-between" sm={8}>
            <Grid item xs={5} sm={6}>
              <TextField
                label="Enter the date range (YYYY-MM)"
                {...register("dateRange")}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              {/* //create a select form element with either "Resound Corporation" or "Starkey" as the options and call it manufacturer, register it with react hook forms */}
              <select {...register("manufacturer")}>
                <option value="Resound Corporation">Resound Corporation</option>
                <option value="Starkey">Starkey</option>
              </select>
            </Grid>
            <Grid item xs={7} sm={6}>
              <Button
                variant="contained"
                type="submit"
                style={{ width: "100%" }}
                disabled={submitSpinner}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {/* Matched Transactions */}
      <Typography variant="h6" mt={3}>
        Matched Transactions
      </Typography>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderMatchedTransactionRows(accountingData.matchedTransactions)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Outstanding Transactions */}
      <Typography variant="h6" mt={3}>
        Outstanding Transactions
      </Typography>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderOutstandingRows(accountingData.outstanding)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Unmatched Bills */}
      <Typography variant="h6" mt={3}>
        Unmatched Bills
      </Typography>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderBillRows(accountingData.unmatchedBills)}</TableBody>
        </Table>
      </TableContainer>

      {/* Unmatched Credits */}
      <Typography variant="h6" mt={3}>
        Unmatched Credits
      </Typography>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCreditRows(accountingData.unmatchedCredits)}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Accounting;
